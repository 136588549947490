<ion-split-pane when="xs" contentId="main-content" *ngIf="userIsLoggedIn">
    <ion-menu contentId="main-content" class="no-border" [disabled]="menuDisabled">
        <ion-header class="ion-no-border">
            <ion-title class="nasalization-font logo ion-no-padding" (click)="navigateHome()">
                {{userIsOnMobile ? 'A⁹' : 'AVALON⁹'}}
            </ion-title>
            <div *ngIf="!userIsOnMobile">
                <ion-grid>
                    <ion-row>
                        <ion-col size="4">
                            <ion-avatar>
                                <img alt="Silhouette of a person's head"
                                    [src]="authService.getCurrentUserPhotoURL() || 'assets/images/placeholder.jpg'" />
                            </ion-avatar>
                        </ion-col>
                        <ion-col size="8" class="welcome-section">
                            <ion-row class="welcome-text">
                                Bienvenido,
                            </ion-row>
                            <ion-row>
                                {{userFullName}}
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </ion-header>
        <ion-content>
            <div class="general-list-header" *ngIf="!userIsOnMobile">General</div>
            <ion-list class="ion-no-padding menu-list">
                <ion-item [routerLink]="card.url" lines="none" [ngClass]="userIsOnMobile ? 'ion-no-padding' : ''"
                    *ngFor="let card of generalAccessibleCards">
                    <ion-icon [name]="card.icon"></ion-icon>
                    <ion-label>{{card.title}}</ion-label>
                    <ion-badge *ngIf="card.badge != '0'">{{card.badge}}</ion-badge>
                </ion-item>
            </ion-list>

            <div class="administrative-list-header" *ngIf="!userIsOnMobile">Administración</div>
            <ion-list class="ion-no-padding menu-list">
                <ion-item [routerLink]="card.url" lines="none" [ngClass]="userIsOnMobile ? 'ion-no-padding' : ''"
                    *ngFor="let card of administrativeAccessibleCards">
                    <ion-icon [name]="card.icon"></ion-icon>
                    <ion-label>{{card.title}}</ion-label>
                    <ion-badge *ngIf="card.badge != '0'">{{card.badge}}</ion-badge>
                </ion-item>
            </ion-list>

        </ion-content>
    </ion-menu>
    <div id="main-content">
        <ion-header>
            <ion-toolbar class="tooolbar">
                <ion-buttons slot="start">
                    <ion-menu-button autoHide="false" (click)="toggleMenu()"></ion-menu-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button (click)="openMessagesPopover($event)">
                        <ion-icon name="mail-outline"></ion-icon>
                    </ion-button>
                    <ion-button (click)="openProfilePopover($event)">
                        <ion-avatar slot="start" class="toolbar-avatar">
                            <img alt="Silhouette of a person's head"
                                [src]="authService.getCurrentUserPhotoURL() || 'assets/images/placeholder.jpg'" />
                        </ion-avatar>
                        <ion-label>{{userFullName}}</ion-label>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-router-outlet></ion-router-outlet>
        </ion-content>
    </div>
</ion-split-pane>

<div *ngIf="!userIsLoggedIn">
    <ion-router-outlet id="main-content">
    </ion-router-outlet>
</div>