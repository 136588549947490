import { provideHttpClient } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp, } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(
      IonicModule.forRoot({
        mode: 'md'
      }),
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideFirestore(() => {
        const firestore = getFirestore(getApp());

        if (environment.useEmulators)
          connectFirestoreEmulator(firestore, '127.0.0.1', 8080);

        return firestore
      }),
      provideStorage(() => {
        const storage = getStorage(getApp());

        if (environment.useEmulators)
          connectStorageEmulator(storage, '127.0.0.1', 8080);

        return storage
      }),
      provideFunctions(() => {
        const functions = getFunctions(getApp(), 'southamerica-east1');

        if (environment.useEmulators)
          connectFunctionsEmulator(functions, '127.0.0.1', 5001);

        return functions;
      }),
      provideAuth(() => {
        const auth = getAuth(getApp());

        if (environment.useEmulators)
          connectAuthEmulator(auth, 'http://127.0.0.1:9099');

        return auth;
      }),
      provideRemoteConfig(() => {
        const remoteConfig = getRemoteConfig(getApp());
        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
        remoteConfig.defaultConfig = {
          'showNewArea': true
        }
        
        return remoteConfig;
      }),
      ToastrModule.forRoot(),
      BrowserAnimationsModule,
      MqttModule.forRoot(environment.MQTT_SERVICE_OPTIONS as IMqttServiceOptions),
    ),
    provideRouter(routes),
    provideHttpClient(),
  ]
});
