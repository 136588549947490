import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule, PopoverController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterLink, RouterLinkActive, CommonModule],
})
export class ProfilePopoverComponent  implements OnInit {

  auth: Auth = inject(Auth);
  
  constructor(
    private popoverController: PopoverController,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {}

  async logout() {
    await this.auth.signOut();
    this.router.navigate(['/login']);
    this.popoverController.dismiss();
  }

  redirectToProfilePage() {
    this.router.navigate([`/perfil/${this.authService.getCurrentUser().uid}`]);
    this.popoverController.dismiss();
  }

}
