import { Injectable, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionExpirationGuard implements CanActivateChild {

  auth: Auth = inject(Auth);

  constructor(private router: Router) { }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const lastSignInTime = this.auth.currentUser?.metadata.lastSignInTime;

    if (lastSignInTime == undefined) {
      return true;
    }

    const haveTenHourDifference = this.haveTenHourDifference(new Date(lastSignInTime), new Date());

    if (haveTenHourDifference) {
      this.auth.signOut().then(() => this.router.navigate(['/login']));
      return false;
    }

    return true;
  }

  haveTenHourDifference(date1: Date, date2: Date): boolean {
    const diffInMs = Math.abs(date2.getTime() - date1.getTime());
    const diffInHours = diffInMs / (1000 * 60 * 60);
    return Math.round(diffInHours) >= 10;
  }
}
