import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private dataSubject: Subject<any> = new Subject<any>();

  communication$: Observable<any> = this.dataSubject.asObservable();

  constructor() {}

  pushData(data: any) {
    this.dataSubject.next(data);
  }
}
