import { Injectable, inject } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  private functions: Functions = inject(Functions);

  constructor() { }

  async callFunction(name: string, data: unknown) {
    return await httpsCallable(this.functions, name)(data);
  }
}
