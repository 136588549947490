import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { RemoteConfig, fetchAndActivate } from '@angular/fire/remote-config';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MessagesPopoverComponent } from './messages-popover/messages-popover.component';
import { ProfilePopoverComponent } from './profile-popover/profile-popover.component';
import { AuthService } from './services/auth/auth.service';
import { CommunicationService } from './services/communication/communication.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterLink, RouterLinkActive, CommonModule],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.userIsOnMobile = event.target.innerWidth <= 992;
  }

  auth: Auth = inject(Auth);
  firestore: Firestore = inject(Firestore);
  remoteConfig = inject(RemoteConfig);

  userIsSuperadmin = false;
  userIsOnMobile = window.innerWidth <= 992;
  menuDisabled = false;

  private organizationDataSubscription?: Subscription;

  cards: any[] = [
    {
      title: 'Inicio',
      icon: 'home',
      section: 'general',
      url: 'inicio',
      allowedRoles: ['anyone']
    },
    {
      title: 'Áreas',
      icon: 'bar-chart',
      section: 'general',
      url: 'areas',
      allowedRoles: ['areas_r', 'areas_p']
    },
    {
      title: 'Alertas',
      icon: 'warning',
      section: 'general',
      url: 'alertas',
      badge: '',
      allowedRoles: ['alerts_r', 'alerts_rw']
    },
    {
      title: 'Dispositivos',
      icon: 'wifi',
      section: 'general',
      url: 'dispositivos',
      allowedRoles: ['superadmin', 'devices_r', 'devices_rw']
    },
    {
      title: 'Manuales',
      icon: 'newspaper',
      section: 'general',
      url: 'manuales',
      allowedRoles: ['superadmin', 'manuals_r']
    },
    {
      title: 'Organizaciones',
      icon: 'briefcase',
      section: 'administrative',
      url: 'organizaciones',
      allowedRoles: ['superadmin']
    },
    {
      title: 'Usuarios',
      icon: 'people',
      section: 'administrative',
      url: 'usuarios',
      allowedRoles: ['superadmin', 'users_r', 'users_rw']
    },
    {
      title: 'Facturación',
      icon: 'reader',
      section: 'administrative',
      url: 'facturacion',
      allowedRoles: ['superadmin', 'billing_r']
    },
    {
      title: 'Soporte',
      icon: 'headset',
      section: 'administrative',
      url: 'soporte',
      allowedRoles: ['support_rw']
    }
  ];

  generalAccessibleCards: any;
  administrativeAccessibleCards: any;

  constructor(private router: Router,
    public authService: AuthService,
    private popoverController: PopoverController,
    private communicationService: CommunicationService
  ) { }

  async ngOnInit() {
    this.auth.onAuthStateChanged(async (data) => {
      if (data) { // user is logged in
        await this.initializeMenu();
      }
    });

    await this.initializeMenu();
  }

  async initializeMenu() {
    const accessibleCards = await this.getAccessibleCards();
    this.generalAccessibleCards = accessibleCards.filter(x => x.section == 'general');
    this.administrativeAccessibleCards = accessibleCards.filter(x => x.section == 'administrative');

    await fetchAndActivate(this.remoteConfig);

    this.userIsSuperadmin = this.authService.userHasRole('superadmin');

    if (!this.userIsSuperadmin) {
      var organizationData = docData(doc(this.firestore, `organizations/${this.authService.getCurrentUserOrganization().name}`));

      this.organizationDataSubscription = organizationData.subscribe(async data => {
        const alertsCard = this.cards.find(card => card.title === 'Alertas');
        const billingCard = this.cards.find(card => card.title === 'Facturación');
        const devicesCard = this.cards.find(card => card.title === 'Dispositivos');

        alertsCard!.badge = data['unattendedFiredAlertsCount'];
        billingCard!.badge = data['pendingBillsCount'];
        devicesCard!.badge = data['offlineDevicesCount'];
      });
    }
  }
  get userIsLoggedIn() {
    return this.authService.userIsLoggedIn();
  }

  get userFullName() {
    return this.authService.getCurrentUserFullName();
  }

  toggleMenu() {
    this.menuDisabled = !this.menuDisabled;
  }

  async openProfilePopover($event: any) {
    const popover = await this.popoverController.create({
      component: ProfilePopoverComponent,
      event: $event
    })
    popover.present();
  }

  async openMessagesPopover($event: any) {
    const popover = await this.popoverController.create({
      component: MessagesPopoverComponent,
      event: $event
    })
    popover.present();
  }

  navigateHome() {
    this.communicationService.pushData({ event: 'navigateHome' });
    this.router.navigate(['inicio']);
  }

  async getAccessibleCards() {
    const userRoles = this.authService.getCurrentUserRoles();

    return this.cards.filter(card => card.allowedRoles.some((role: any) => role == 'anyone' || userRoles.includes(role)));
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }
}
