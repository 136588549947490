import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messages-popover',
  templateUrl: './messages-popover.component.html',
  styleUrls: ['./messages-popover.component.scss'],
  standalone: true
})
export class MessagesPopoverComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
