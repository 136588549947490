import { Injectable, inject } from '@angular/core';
import { Auth, browserLocalPersistence, signInWithCustomToken } from '@angular/fire/auth';
import { setPersistence } from 'firebase/auth';
import jwtDecode from 'jwt-decode';
import { FunctionsService } from '../functions/functions.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth: Auth = inject(Auth);

  constructor(
    private functionsService: FunctionsService,
  ) { }

  async login(username: string, password: string) {
    await setPersistence(this.auth, browserLocalPersistence);

    const customToken = await this.functionsService.callFunction('createCustomToken', { username, password });

    localStorage.setItem('token', customToken.data as string);
    await signInWithCustomToken(this.auth, customToken.data as string);
  }

  userIsLoggedIn() {
    return this.auth.currentUser != null;
  }

  getCurrentUser(): any {
    return jwtDecode(localStorage.getItem('token') as string);
  }

  getCurrentUserFullName(): string {
    return this.getCurrentUserClaim('fullName');
  }

  getCurrentUserPhotoURL(): string {
    return this.getCurrentUserClaim('photoURL');
  }

  getCurrentUserClaim(claim: string) {
    return this.getCurrentUser().claims[claim];
  }

  getCurrentUserOrganization(): { name: string, shortName: string } {
    return this.getCurrentUserClaim('organization');
  }

  getCurrentUserEmail(): string[] {
    return this.getCurrentUserClaim('email')
  }

  getCurrentUserRoles(): string[] {
    return this.getCurrentUserClaim('roles')

  }

  getCurrentUserMqttId(): string {
    return this.getCurrentUserClaim('mqttId')
  }

  userHasRole(role: string): boolean {
    return this.getCurrentUserRoles().indexOf(role) != -1;
  }
}
